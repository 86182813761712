import React, { useState } from "react";
import { Link } from "react-router-dom";
import ruleinfo from "../data/data2";
import { FaDoorOpen } from "react-icons/fa";
import Accordion from "react-bootstrap/Accordion";
import { BsExclamationSquare } from "react-icons/bs";

import "animate.css";
const Rules = () => {
  return (
    <>
      <div className="row  justify-content-center m-5 animate__animated animate__bounceInLeft ">
        <h4 className=" h1 text-center mb-3 mt-1 text-white  ">Szabályzat</h4>
      </div>
      <section className="container-sm ">
        <div className="flex-row bg-dark bg-opacity-50 justify-content-center text-center animate__animated animate__bounceInLeft">
          <div className="col-sm-12 justify-content-center text-center">
            <Accordion defaultActiveKey="0">
              {ruleinfo.map((rules, index) => {
                const { id, question, answear } = rules;
                return (
                  <div className=" col-sm-12 text-center" key={index}>
                    <Accordion.Item eventKey={id}>
                      <Accordion.Header>
                        <h6 className="text-white ">{question}</h6>
                      </Accordion.Header>

                      <Accordion.Body>
                        <p className="text-white ">{answear}</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                );
              })}
            </Accordion>
          </div>
          <p className="text-center text-white">
            <i className="text-center text-white">
              Egyéb kérdések esetén keressetek bátran bennünket!
            </i>
          </p>
        </div>
      </section>
    </>
  );
};
export default Rules;
