export const images = [
  {
    id: 1,
    title: "Bemutató",
    text: ` A férfiak számára is fontos a jó megjelenés, amelyhez a hajuk állapota és formája nagymértékben hozzájárul.  Szalonunkban fodrászaink segítségedre lesznek a megfelelő férfi frizura kiválasztásában. Ha pedig elhoznád a család legkisebb szívtipróját egy gyors hajvágásra, akkor állunk rendelkezésedre, ebben is számíthatsz ránk. 
Konzultálj szakembereinkkel.
 
`,
    image: "",
  },
];

export default images;
