import képek1 from "../imageall/balayageimg/balayage04.webp";
import képek2 from "../imageall/balayageimg/balayage02.webp";
import képek3 from "../imageall/balayageimg/balayage03.webp";
import képek4 from "../imageall/balayageimg/balayage01.webp";
export const images = [
  {
    id: 1,
    title: "Bemutató",
    text: ` A Balayage szó első sorban „seprést” jelent. Az eljárást ecsetekkel, szabadkézzel vagy fóliával készítjük, ez függ a kívánt hajszíntől! A hajtincseket passzénként választjuk le és  aprólékos mozdulatokkal festjük, így a fodrásznál töltött idő átlagosan három-öt óra!
A technika helyes kivitelezéséhez tapasztalatra és kellő szakmai tudásra van szüksége a Fodrásznak!
A balayage festés minden hajhosszon egyaránt jól mutat, ajánljuk hullámos és egyenes verzióban is!
`,
    image: képek1,
  },
  {
    id: 2,
    title: "Megjelenése",
    text: `  A Balayage 2015-ben került be a köztudatba széles körben, azóta is hódit  az ombre,airtouch és a babylights mellett. `,
    image: képek2,
  },
  {
    id: 3,
    title: "Cél",
    text: ` A Balayage nem a kontrasztos megjelenést hordozza magában, sokkal inkább a lágyabb, kifinomultabb színhatást kelti, amelyet egy hajvágással még szebbé lehet tenni. Eredményképp pedig  több hónap elteltével is tökéletes lesz a kívánt hajszín .Népszerűsége töretlen. A legkedveltebb eljárások közzé tartozik napjainkban. A színfrissítést két-három havonta javasoljuk,megtartva ezzel fényét és ápoltságát!
`,
    image: képek3,
  },
  {
    id: 4,
    title: "Ápolása",
    text: `Kötéserősítő, hajszínvédő és hajszínfrissítő termékek használata javasolt!Fodrászodnál érdemes részletesebb , személyre szabottabb tanácsadást kérni!`,
    image: képek4,
  },
];
export default images;
