export const ruleinfo = [
  {
    id: 1,
    question: "Lehet időpontot kérni?",
    answear:
      "Igen, természetesen. Messenger,Instagram illetve telefonos elérhetőségünk is megtaláljátok az oldalunkon . Azonban nem tudjuk biztosítani, hogy azonnal tudunk reagálni.",
  },
  {
    id: 2,
    question: "Mennyi időt vesz igénybe a szolgáltatás?",
    answear: "A helyszínen derül ki miután láttuk mivel dolgozunk.",
  },
  {
    id: 3,
    question: "Mennyibe fog kerülni a kívánt eljárás??",
    answear:
      "Az árak a weboldalon megtalálhatóak. A pontos összeg megállapítása attól függ, hogy milyen állapotban van a vendég haja , hiszen sok esetben ezt nem tudjuk előre.",
  },
  {
    id: 4,
    question: "Mivel fizethetek??",
    answear:
      "A Szalonunkban a kézpénzes fizetést részesítjük előnyben, bankkártyás fizetés jelenleg nem elérhető.",
  },
  {
    id: 5,
    question: "Kell-e foglaló díjat fizetni?",
    answear:
      "A szakembereink kérhetnek foglaló díjat,annak érdekében ,hogy biztosítsák a pontos érkezést és egyeltalán az időpont komolyan vételét.  Az időpont egyeztetés során a válasz üzenetben elmagyarázzák ennek módját.",
  },
  {
    id: 6,
    question: "Valami közbejött, mégsem jó az időpont!",
    answear:
      "Amennyiben a lefoglalt időpont nem megfelelő, akkor az időpont előzetes egyeztetést követően módosítható vagy lemondható",
  },
  {
    id: 7,
    question: "Meddig módosítható vagy mondható le az időpont?",
    answear:
      "Időpont módosításra lehetőség maximum 24 órával a megbeszélt időpont előtt van .",
  },
  {
    id: 8,
    question:
      "El fogok késni, nem érek oda időben a megbeszélt időpontra, most mit tegyek?",
    answear:
      "Ha a megbeszélt időpontról elkésnél, vagy nem érsz oda időben, akkor kérjük értesíts minket amint tudsz .Késés esetén maximum 15 percig vagyunk hajlandóak várni. E fölött mi döntjük el, hogy elkezdjük a munkát vagy sem",
  },
];

export default ruleinfo;
