import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../imageall/mainimages/bronzecanvabest.webp";
import { links, social } from "../data/navdata";
import { FaBars, FaTwitter } from "react-icons/fa";

import szalon02 from "../imageall/mainimages/background02.webp";
import "animate.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Navbar = () => {
  const [showLinks, setShowLinks] = useState(false);
  /* const [showurl, setShowurl] = useState(""); */
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);

  /* useEffect(() => {
    const actualurl = window.location.href;

    if (actualurl === "http://localhost:3000/home") {
      setShowurl("FŐOLDAL");
    } else if (actualurl === "http://localhost:3000/services") {
      setShowurl("SZOLGÁLTATÁSOK");
    } else if (actualurl === "http://localhost:3000/price") {
      setShowurl("ÁRAK");
    } else if (actualurl === "http://localhost:3000/pictures") {
      setShowurl("KÉPEK");
    } else {
      setShowurl("");
    }
  }); */
  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`;
    } else {
      linksContainerRef.current.style.height = "0px";
    }
  }, [showLinks]);
  return (
    <nav className="container-fluid  navbar sticky-top">
      <div className="row justify-content-center ">
        {/*    <div className="col-sm-6 text-center justify-content-center">
          <LazyLoadImage
            height="auto"
            src={szalon02}
            alt="A Bronze Art Csapata, fodrászok, fodrász szalon"
            className="  shadow-sm shadow-white rounded-3 text-center justify-content-center img-fluid "
            effect="blur"
            srcSet=""
            style={{ width: "100%", height: "" }}
          />{" "}
          <p className=" text-center text-white">
            Budapest IX.ker Liliom utca 48.
          </p>
        </div> */}
      </div>

      <div className="nav-center mt-0">
        <div className="nav-header ">
          <button
            className="nav-toggle animate__animated animate__bounceInLeft"
            onClick={() => setShowLinks(!showLinks)}
            alt="Menü gomb, a navigáláshoz."
            title="Menü gomb, a navigáláshoz."
          >
            <FaBars />
          </button>{" "}
          <p className="text-white  bg-transparent text-center animate__animated animate__flash h1">
            <strong>Üdvözöllek a Bronze Art Studio Oldalán!</strong>
          </p>
        </div>
        <div className="links-container " ref={linksContainerRef}>
          <ul className="links " ref={linksRef}>
            {links.map((link) => {
              const { id, url, icon, text } = link;
              return (
                <li key={id} className="m-1">
                  <Link
                    to={url}
                    className="text-decoration-none"
                    onClick={() => {
                      setShowLinks(!showLinks);
                      window.scrollTo(500, 0);
                    }}
                  >
                    <h6 className="text-white">
                      {icon}
                      {` ${text} `}
                    </h6>
                  </Link>
                </li>
              );
            })}
            {social.map((socials, index) => {
              const { id, url, icon, text } = socials;
              return (
                <li key={id} className="m-1">
                  <a
                    href={url}
                    className="text-decoration-none"
                    title="bronze art kereső"
                  >
                    <h6 className="text-white">
                      {icon}
                      {` ${text} `}
                    </h6>
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        <p className="text-center">
          <a
            href="/home/#bemutatkozoid"
            className="text-decoration-none text-white "
          >
            Olvassátok el a bemutatkozónkat!
          </a>{" "}
          <span class="badge bg-white text-black"> Tipp!</span>
          {/* <p className="text-white text-center">{showurl}</p> */}
        </p>
      </div>
    </nav>
  );
};

export default Navbar;
