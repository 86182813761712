import képek1 from "../imageall/olapleximg/olaplexhair.webp";
export const images = [
  {
    id: 1,
    title: "Bemutató",
    text: ` Az Olaplex egy olyan hajregeneráló kezelés, amely több lépésből áll. 
Megóvja a hajad a sérülésektől, azonban ha már sérült hajról beszélünk, akkor hozzájárul annak újjáépítésében, támogatja a hajkötéseket. Rendszeres használata mellett hajad selymes, puha tapintásúvá és kezelhetőbbé válik.  A korábbi sérülések újjáépítése által, a haj újból erőtől duzzadó és hidratált lesz. A kezelést követően azonnal észrevehető a változás, amely mindenkit elvarázsol. Ha szeretnél úgy tündökölni, mint a legnagyobb sztárok, akkor próbáld ki az Olaplex kezeléseinket. 
`,
    image: képek1,
  },
];

export default images;
