import img1 from "../imageall/images2/igora01.webp";
import img2 from "../imageall/images2/igora04.webp";
import img3 from "../imageall/images2/loreal01.webp";
import img4 from "../imageall/images2/nevitaly1.webp";
import img5 from "../imageall/images2/nevitaly0001.webp";
import img6 from "../imageall/images2/nevitaly3.webp";
import img8 from "../imageall/images2/nevitali4.webp";
import img9 from "../imageall/images2/olaplex002.webp";
import img10 from "../imageall/images2/olaplex1.webp";

const reviewsdata = [
  {
    id: 1,
    image: img1,
    title: "Schwarzkopf",
    quote:
      "A Schwarzkopf egy olyan kozmetikai márka, amely a minőséget, a szakértelmet és az újítást jelenti a világban már több mint 120 éve. A Schwarzkopf márka a vásárlók és a profi fodrászok teljes bizalmát élvezi.",
  },
  {
    id: 2,
    image: img2,
    title: "Schwarzkopf",
    quote:
      "Szabaduljon meg az ősz hajszálaktól és csábítson tökéletes hajkoronájával! A Schwarzkopf Professional IGORA Royal hajfesték intenzíven színezi a hajat, tökéletesen elfedi az ősz hajszálakat és különleges, ápoló összetevőket tartalmaz, amelyeknek köszönhetően a haj szó szerint ragyogni fog az egészségtől. Engedje meg magának ön is ezt az luxust! ",
  },
  {
    id: 3,
    image: img3,
    title: "L'Oréal",
    quote:
      "A L'Oréal Paris kozmetikát kompromisszumot nem ismerő minőség, valamint a nők és férfiak mindennapi életéhez tartozó széles termékválaszték jellemzi. A L'Oréal Paris filozófiája egyszerű - széles skálát felölelő, maximális hatékonyságú és innovatív termékeket kínál az ügyfelek számára",
  },
  {
    id: 4,
    image: img4,
    title: "Nevitaly",
    quote:
      "Miért állítják, hogy a hajfestés fókuszába az egészség is kerülhet? A Nevitaly CC Color antibakteriális hatóanyagokat is tartalmaz, miközben teljesen ammónia- és parafeniléndiamin- (PPD) mentes. ",
  },
  {
    id: 5,
    image: img5,
    title: "Nevitaly",
    quote:
      "Extra védelmet is nyújt a fejbőrnek bizonyos illóolajok, gesztenyekivonat és dióhéj felhasználásával. ",
  },
  {
    id: 6,
    image: img6,
    title: "Nevitaly",
    quote:
      "A Nevitaly CC Color egy olyan nyugtató és tápláló tartós hajfestési kezelés, mely kiegyensúlyozó hatással bír, gondoskodik a fejbőr egészségéről, puhítja és megemeli a hajat.",
  },
  {
    id: 7,
    image: img8,
    title: "Nevitaly",
    quote:
      "Ajánljuk: Kismamáknak,allergiában szenvedőknek és érzékeny fejbőrűeknek.",
  },
  {
    id: 8,
    image: img9,
    title: "Olaplex",
    quote: "Gyönyörű tincsek, erősen és egészségesen",
  },
  {
    id: 8,
    image: img10,
    title: "Olaplex",
    quote:
      "Védi és helyreállítja a sérült hajat, megelőzi a hajvégek töredezettségének kialakulását és a szöszösödést, azzal, hogy újraegyesíti a haj sérült kötéseit.",
  },
];
export default reviewsdata;
