import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import { links, social } from "../data/navdata";
import { FaBars, FaTwitter } from "react-icons/fa";
import contactinfo from "../data/data";
import { FaDoorOpen, FaHandPointer } from "react-icons/fa";
import { SiFacebook, SiInstagram } from "react-icons/si";
import { RiHome5Fill } from "react-icons/ri";

const Footer = () => {
  return (
    <footer className="container-fluid bg-black bg-opactiy-75  shadow-sm shadow-white   mt-3 justify-content-center mb-2 position-relative">
      <div className="row justify-content-center bg-black bg-opacity-10 ">
        <div className="col-sm-2 ">
          <h4 className=" h1 text-center mb-5 text-white text-center">
            Kapcsolat
          </h4>
          <hr className="text-white" />
        </div>
      </div>

      {contactinfo.map((info, index) => {
        const { phone, phoneicon, address, addressicon, openingHours } = info;

        return (
          <div
            className="row justify-conten-cente text-start bg-black bg-opacity-10"
            key={index}
          >
            <div className="col justify-content-center  ">
              <h5 className="text-white">
                <u>
                  <FaDoorOpen /> Nyitvatartás:
                </u>
              </h5>

              {openingHours.map((dayinfo, index) => {
                const { day } = dayinfo;
                return (
                  <div className="col-sm-12 m-0 p-0 " key={index}>
                    <ul className="list-group fw-light list-group-flush  text-white bg-transparent m-0 p-0">
                      <li className="list-group-item text-white bg-transparent  m-0 p-0">
                        {day}
                      </li>
                    </ul>
                  </div>
                );
              })}
            </div>

            <div className="col justify-content-start ">
              <h5 className="text-white text-end">
                <u>{phoneicon} Elérhetőség</u>
              </h5>
              <ul className="text-start fw-light">
                <li className=" text-white bg-transparent text-end">
                  Bronze Art Studio <br /> Budapest IX.ker Liliom utca 48
                </li>
                <li className=" text-white bg-transparent text-end ">
                  +36 (30) 7812503
                </li>
                <li className="text-white bg-transparent text-end">
                  bronzeart.hu
                </li>
              </ul>
            </div>
            <div className="row ">
              <p className="text-white display-6 text-center">
                <a
                  href="https://www.facebook.com/bronzeartstudio"
                  className="m-2 text-white text-decoration-none"
                >
                  <SiFacebook /> Facebook
                </a>
                <a
                  href="https://www.instagram.com/explore/locations/1256583641060616/bronze-art-studio/"
                  className="text-white m-2 text-decoration-none"
                >
                  <SiInstagram /> Instagram
                </a>
                <a
                  href="https://goo.gl/maps/dt8mPArgjRNNBp3B9"
                  className="m-2 text-white text-decoration-none"
                >
                  {addressicon}
                  Map
                </a>
              </p>
            </div>
          </div>
        );
      })}
    </footer>
  );
};
export default Footer;
