import képek1 from "../imageall/Images/1.webp";
import képek2 from "../imageall/Images/2.webp";
import képek3 from "../imageall/Images/3.webp";
import képek4 from "../imageall/Images/4.webp";
import képek5 from "../imageall/Images/5.webp";
import képek6 from "../imageall/Images/6.webp";
import képek7 from "../imageall/Images/7.webp";
import képek8 from "../imageall/Images/8.webp";
import képek9 from "../imageall/Images/9.webp";
import képek10 from "../imageall/Images/10.webp";
import képek11 from "../imageall/Images/11.webp";
import képek12 from "../imageall/Images/12.webp";
import képek13 from "../imageall/Images/13.webp";
import képek14 from "../imageall/Images/14.webp";
import képek15 from "../imageall/Images/15.webp";
import képek16 from "../imageall/Images/16.webp";
import képek17 from "../imageall/Images/17.webp";
import képek18 from "../imageall/Images/18.webp";
import képek19 from "../imageall/Images/19.webp";
import képek20 from "../imageall/Images/20.webp";
import képek21 from "../imageall/Images/21.webp";
import képek22 from "../imageall/Images/22.webp";
import képek23 from "../imageall/Images/23.webp";
import képek24 from "../imageall/Images/24.webp";
import képek25 from "../imageall/Images/25.webp";
import képek26 from "../imageall/Images/26.webp";
import képek27 from "../imageall/Images/27.webp";
import képek28 from "../imageall/Images/28.webp";
import képek29 from "../imageall/Images/29.webp";
import képek30 from "../imageall/Images/30.webp";

export const images = [
  {
    id: 1,
    img: képek1,
  },
  {
    id: 2,
    img: képek2,
  },
  {
    id: 3,
    img: képek3,
  },
  {
    id: 4,
    img: képek4,
  },
  {
    id: 5,
    img: képek5,
  },
  {
    id: 6,
    img: képek6,
  },
  {
    id: 7,
    img: képek7,
  },
  {
    id: 8,
    img: képek8,
  },
  {
    id: 9,
    img: képek9,
  },
  {
    id: 10,
    img: képek10,
  },
  {
    id: 11,
    img: képek11,
  },
  {
    id: 12,
    img: képek12,
  },
  {
    id: 13,
    img: képek13,
  },
  {
    id: 14,
    img: képek14,
  },
  {
    id: 15,
    img: képek15,
  },
  {
    id: 16,
    img: képek16,
  },
  {
    id: 17,
    img: képek17,
  },
  {
    id: 18,
    img: képek18,
  },
  {
    id: 19,
    img: képek19,
  },
  {
    id: 20,
    img: képek20,
  },
  {
    id: 21,
    img: képek21,
  },
  {
    id: 22,
    img: képek22,
  },
  {
    id: 23,
    img: képek23,
  },
  {
    id: 24,
    img: képek24,
  },
  {
    id: 25,
    img: képek25,
  },
  {
    id: 26,
    img: képek26,
  },
  {
    id: 27,
    img: képek27,
  },
  {
    id: 28,
    img: képek28,
  },
  {
    id: 29,
    img: képek29,
  },
  {
    id: 30,
    img: képek30,
  },
];
export default images;
