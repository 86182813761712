import React from "react";
import Accordion from "react-bootstrap/Accordion";
import "./css/main.min.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Routes,
} from "react-router-dom";
// import pages
import Home from "./pages/Home";
import Pictures from "./pages/Pictures";
import Price from "./pages/Price";
import Rules from "./pages/Rules";
import Services from "./pages/Services";
import Error from "./pages/Error";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import Ombre from "./pages/ombre";
import Olaplex from "./pages/olaplex";
import FerfiGyerek from "./pages/ferfigyerek";
import Balayage from "./pages/balayage";
import Alkalmi from "./pages/alkalmi";
import Babylights from "./pages/babylights";
import JOICOK from "./pages/joicok";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/home" element={<Home />} />
        <Route path="/pictures" element={<Pictures />} />
        <Route path="/price" element={<Price />} />
        <Route path="/rules" element={<Rules />} />
        <Route path="/services" element={<Services />} />
        <Route path="/ombre" element={<Ombre />} />
        <Route path="/olaplex" element={<Olaplex />} />
        <Route path="/balayage" element={<Balayage />} />
        <Route path="/ferfigyerek" element={<FerfiGyerek />} />
        <Route path="/alkalmi" element={<Alkalmi />} />
        <Route path="/babylights" element={<Babylights />} />
        <Route path="/joicok" element={<JOICOK />} />
        <Route path="/*" element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  );
}
export default App;
