import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { images } from "../data/olaplexdata";
import { BsCaretRightSquare } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const PictureAndText = () => {
  const [loading, setLodaing] = useState(true);
  const [data, setData] = useState({});

  const getAllImageAndText = () => {
    setData(images);
  };

  useEffect(() => {
    getAllImageAndText();
    setInterval(() => {
      setLodaing(false);
    }, 1000);
  }, []);
  if (loading) {
    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 justify-content-center">
            <div class="text-center">
              <span class="text-center text-dark ">LOADING. . .</span>
              <div
                class="spinner-border display-1 text-dark"
                role="status"
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="row m-3">
        <h1
          style={{ letterSpacing: "8px" }}
          className="h1 text-center text-white"
        >
          OLAPLEX
        </h1>
      </div>
      <section className="container-sm">
        {data.map((datas, index) => {
          const { id, title, image, text } = datas;
          return (
            <div key={index} className="row justify-content-center ">
              <div class="d-flex bg-dark bg-opacity-50 m-3 align-items-center">
                <div className="col-sm-3 text-center m-1">
                  <h5 className=" text-white ">{title}</h5>
                </div>

                <div className="col-sm-8 text-right ">
                  <blockquote className="blockquote text-white bg-black bg-opacity-25 text-center font-monospace mt-3">
                    {text}
                  </blockquote>
                </div>
              </div>{" "}
              <div class="d-flex justify-content-center bg-dark bg-opacity-50  ">
                <div className="col-sm-4 m-3">
                  <LazyLoadImage
                    className="rounded-5 shadow-sm shadow-white"
                    src={image}
                    alt="Bronze Art Studóban készült fénykép, tökéletes hajformák, kiváló munka , gyönyörű színek."
                    title="Ombre!"
                    loading="lazy"
                    effect="blur"
                  />
                </div>
              </div>
            </div>
          );
        })}{" "}
        <div className="row justify-content-center bg-dark bg-opacity-50 m-2">
          <div className="col-sm-8   text-center">
            <h3 className="text-center text-white">További cikkeink!</h3>
            <ul className="list-group list-group-horizontal-md fw-light ">
              <li className="text-center list-group-item list-group-item-dark">
                <a className="text-decoration-none" href="/ombre">
                  OMBRE
                </a>
              </li>

              <li className="text-center list-group-item list-group-item-dark">
                <a className="text-decoration-none" href="/olaplex">
                  OLAPLEX
                </a>
              </li>

              <li className="text-center list-group-item  list-group-item-dark">
                <a className="text-decoration-none" href="/babylights">
                  BABYLIGHTS
                </a>
              </li>

              <li className="text-center list-group-item list-group-item-dark">
                <a className="text-decoration-none" href="/balayage">
                  BALAYAGE
                </a>
              </li>

              <li className="text-center list-group-item list-group-item-dark">
                <a className="text-decoration-none" href="/joicok">
                  JOICO K-Pak
                </a>
              </li>
              <li className="text-center list-group-item list-group-item-dark">
                <a className="text-decoration-none" href="/alkalmi">
                  ALKALMI FRIZURA
                </a>
              </li>
              <li className="text-center list-group-item list-group-item-dark">
                <a className="text-decoration-none" href="/ferfigyerek">
                  FÉRFI ÉS GYEREK HAJVÁGÁS
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};
export default PictureAndText;
