import React, { useEffect, useState } from "react";
import contactinfo from "../data/data";
import reviewsdata from "../data/sliderdata";
import { FaDoorOpen, FaHtml5 } from "react-icons/fa";
import { FaQuoteRight, FaHandHoldingHeart } from "react-icons/fa";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { BsExclamationSquare } from "react-icons/bs";
import { RiH3, RiWalkFill } from "react-icons/ri";

import adri from "../imageall/mainimages/adribest.webp";

import hairs from "../imageall/mainimages/szalonteam006.webp";
import {
  BsCloudHaze2Fill,
  BsPalette,
  BsPinAngle,
  BsScissors,
} from "react-icons/bs";
import { GiBookmarklet } from "react-icons/gi";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "animate.css";

const Home = () => {
  //use statek a sliderhez

  const [people, setPeople] = useState(reviewsdata);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  //2.useeffect
  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 7000);
    return () => clearInterval(slider);
  }, [index]);

  return (
    <>
      <div className="row  justify-content-center m-5 animate__animated animate__bounceInLeft ">
        <h4 className="h1 text-center  text-white  ">Főoldal</h4>
      </div>

      <section className="container-sm justify-content-center">
        <div className="flex-row  justify-content-center animate__animated animate__bounceInLeft">
          <div className="card shadow-sm shadow-white bg-dark bg-opacity-50 ">
            <div className="card-header text-center">
              <h1
                className="text-white fw-light"
                title="Bronze Art Studio hivatalos oldala, IX.ker Liliom utca 48."
              >
                Balayage, Olaplex, Babylights, Ombre,Joico,Alkalmi frizurák,
                Férfi és Gyermek hajvágás!
              </h1>
            </div>
            {/* <div className="card-img ">
                <LazyLoadImage
                  src={szalon01}
                  width="100%"
                  height="auto"
                  alt="Bronze Art Studio szépségszalon. Budapest Liliom utca 48. IX.kerület. Fodrászt keresel? Nézz be hozzánk!"
                  className="border border-white rounded-3"
                  style={{ width: "", height: "" }}
                  effect="blur"
                />
              </div> */}
            <div className="card-footer">
              <h2 className="text-center text-white bg-transparent">
                Látogass el hozzánk! <br />
                <RiWalkFill />
              </h2>
            </div>
          </div>
        </div>
        <div className="flex-row bg-dark bg-opacity-50 justify-content-center animate__animated animate__bounceInLeft mt-3 text-center">
          <h3 className="text-center text-white mb-2">
            <BsScissors /> Alapanyagaink
          </h3>
          <hr />
          <div className="section-center ">
            {people.map((review, reviewsIndex) => {
              const { id, title, image, quote } = review;
              let position = "nextSlide";
              if (reviewsIndex === index) {
                position = "activeSlide";
              }
              if (
                reviewsIndex === index - 1 ||
                (index === 0 && reviewsIndex === people.length - 1)
              ) {
                position = "lastIndex";
              }

              return (
                <article className={`${position}`} key={id}>
                  <h3 className=" text-white bg-transparent mb-2  ">
                    {title}
                    <hr />
                  </h3>
                  <LazyLoadImage
                    src={image}
                    alt={title}
                    className="person-img border         bg-dark m-0"
                    loading="lazy"
                    effect="blur"
                    style={{ width: "", height: "" }}
                  />
                  <blockquote className=" blockquote text-white  bg-transparent  p-1     bg-black bg-opacity-10 fw-light">
                    {quote}
                    <br />
                    <FaQuoteRight className="icon" />
                  </blockquote>
                </article>
              );
            })}
            <button
              className="prev border border-2  border-dark"
              alt="hátra lapozó gomb"
              title="hátra lapozó gomb"
              onClick={() => setIndex(index - 1)}
            >
              <FiChevronLeft />
            </button>
            <button
              className="next border border-2  border-dark"
              alt="előrre lapozó gomb"
              title="előrre lapozó gomb"
              onClick={() => setIndex(index + 1)}
            >
              <FiChevronRight />
            </button>
          </div>{" "}
          <q className="text-center text-white ">
            <i>A hajad az a korona, amit soha nem veszel le</i>
          </q>
        </div>

        <div className="flex-row justify-content-center bg-transparent    m-1">
          <div className="alert alert-white text-center  " role="alert">
            <p className="text-white display-6 bg-black bg-opacity-25 ">
              Tekintsd meg{" "}
              <a
                href="/Pictures"
                className="alert-link text-center link-white text-white   text-decoration-none   "
              >
                <strong>fodrászaink</strong>
              </a>{" "}
              munkáit!
            </p>
            <i className="display-5 text-white">
              <BsExclamationSquare />
            </i>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-sm-7 text-center  ">
            <LazyLoadImage
              width="100%"
              height="auto"
              src={hairs}
              alt="Bronze Art Csapata.Tomasovszki Melitta, Valla-Mursa Andrea, Csonka Kati."
              className="shadow-sm shadow-white     bg-black bg-opacity-10 rounded-3 justify-content-center img-fluid "
              effect="blur"
              style={{ width: "", height: "" }}
            />
          </div>
        </div>

        <div className="flex-column justify-content-center      bg-transparent">
          <div className="alert alert-white text-center  " role="alert">
            <p className="text-white bg-black bg-opacity-50  display-6  ">
              Szalonunkban profi szakemberek àllnak Vendégeink rendelkezésére ,
              hogy csodát műveljenek frizurájukkal! A foglalással kapcsolatos
              kérdésekre a{" "}
              <strong>
                <a
                  href="/Rules"
                  className="alert-link text-center link-black link:hover link-red text-decoration-none text-white  "
                >
                  Szabályzat oldalon
                </a>{" "}
              </strong>
              találhattok válaszokat!
            </p>
            <i className="display-3 text-white">
              <BsExclamationSquare />
            </i>
          </div>
        </div>
        <div className="row justify-content-center " id="bemutatkozoid">
          <h3 className="text-center text-white ">BEMUTATKOZÓ</h3>
          <hr className="text-dark" />
          <div className="col-sm-6 justify-content-center   m-2">
            <div className="card justify-content-center bg-dark bg-opacity-50   shadow-sm shadow-white  rounded-3  m-0">
              <LazyLoadImage
                src={adri}
                alt="Nagy Adrienn. A Bronze Art Stúdió megállmodója, üzlettulajdonos, vezető fodrász."
                className="card-img bg-black img-fluid "
                effect="blur"
                style={{ width: "", height: "" }}
              />
              <div className="card-footer text-center bg-dark bg-opacity-50">
                <q className="text-white">
                  <i>A haj a lélek tükre</i>
                </q>
              </div>
              <div className="card-body bg-dark bg-opacity-50  text-center ">
                <h4 className="card-title text-white">
                  <strong>
                    <i>Nagy Adrienn</i>
                  </strong>
                </h4>
                <hr />
                <p className="card-text text-white ">
                  <br /> A Bronze Art Studio megállmodója
                  <br /> Üzlettulajdonos <br />
                  Vezető Fodrász
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-row justify-content-center">
          <div className="card bg-dark bg-opacity-50   shadow-sm shadow-white  rounded-3 m-0">
            <div className="card-body bg-transparent ">
              <h3 className="card-title  text-white text-center">
                <GiBookmarklet /> Történetünk
              </h3>

              <hr />
              <blockquote className=" blockquote fw-light text-white bg-transparent text-center">
                Gyermekkorom óta fontos szerepet töltött be az életemben a
                szépség , a nőkkel való kapcsolattartás , szebbnél szebb
                frizurák készitése, így nem volt számomra kérdés , hogy ez az én
                utam! Közel tizenhárom éves szakmai tapasztalattal , alázattal
                es rendíthetetlen munkamorállal sikerült megnyitnom 2019-ben
                csodás kis üzletünket ,ahol vendégeinket egy remek hangulatú
                csapat várja nap mint nap. A fodrászok kiváló munkát végeznek,
                odaadóak és kedvesek, lehet rájuk számítani.
                <hr />
                <ul className="list-group list-group-flush text-white bg-transparent">
                  <li className="list-group-item bg-transparent m-3">
                    <blockquote className=" blockquote text-white bg-transparent text-center">
                      Prémium minőségű alapanyagokkal dolgozunk, mivel
                      odafigyelünk a vendégeinkre.
                    </blockquote>
                  </li>
                  <li className="list-group-item bg-transparent m-3">
                    <blockquote className=" blockquote text-white bg-transparent text-center">
                      Sokan érkeznek hozzánk úgy, hogy nem tudják pontosan mi
                      állna nekik jól.
                    </blockquote>
                  </li>
                  <li className="list-group-item bg-transparent m-3">
                    <blockquote className=" blockquote text-white bg-transparent text-center">
                      Szívesen adunk tanácsot mindenkinek.
                    </blockquote>
                  </li>
                  <li className="list-group-item bg-transparent m-3">
                    <blockquote className=" blockquote text-white bg-transparent text-center">
                      Válasszátok ki a számotokra legmegfelelőbb szolgáltatást!
                    </blockquote>
                  </li>
                  <li className="list-group-item bg-transparent m-3">
                    <blockquote className=" blockquote text-white bg-transparent text-center">
                      {" "}
                      Kérjetek időpontot! Keressetek minket üzenetben vagy
                      telefonon!
                    </blockquote>
                  </li>
                  <li className="list-group-item bg-transparent m-3">
                    <blockquote className=" blockquote text-white bg-transparent text-center">
                      {" "}
                      Minden régi és új vendéget szeretettel várunk!
                    </blockquote>
                  </li>
                </ul>
              </blockquote>
            </div>
            <div className="card-footer text-center bg-transparent ">
              <q className="text-white text-center">
                <i title="fodrász idézetek, szépség idézet">
                  Minden sikeres nő mögött áll egy ügyes fodrász!
                </i>
              </q>
            </div>
          </div>
        </div>

        <div className="flex-row bg-dark bg-opacity-50 mt-3 justify-content-center">
          <div className="card bg-dark bg-opacity-50  shadow-sm shadow-white ">
            <div className="card-body bg-transparent text-center">
              <h3 className=" card-title text-center  text-white">
                <BsPalette /> Szakterületeink
              </h3>
              <div className="card-text">
                <ul class="list-group list-group-flush fw-light ">
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Balayage festés
                      <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Babylights
                      <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Olaplex kötéserősítő kezelés
                      <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Joico újjáépítő kezelés
                      <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Hajhosszabítás <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Teljes hajszín világosítás <BsPinAngle />
                    </strong>
                  </li>

                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Melír <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Férfi hajvágás <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Gyermek hajvágás <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Alkalmi frizurák kontyok <BsPinAngle />
                    </strong>
                  </li>
                  <li class="list-group-item bg-transparent text-white m-3">
                    <strong>
                      Tanácsadás <BsPinAngle />
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-row justify-content-center mt-3 bg-dark bg-opacity-50">
          <div className="alert alert-white  " role="alert">
            <ol class="list-group list-group-numbered bg-dark bg-opacity-10  shadow-sm shadow-white  rounded-3 text-white ">
              <li class="list-group-item d-flex justify-content-between align-items-start bg-transparent text-white ">
                <div class="ms-2 me-auto bg-transparent text-white ">
                  <div class="fw-bold bg-transparent text-white m-2">
                    Tekintsd meg munkáinkat!
                  </div>
                  <a
                    href="/Pictures"
                    className="text-decoration-none text-white bg-black rounded-3 p-1 m-2"
                  >
                    <strong>Képek</strong>
                  </a>
                </div>
                <span class="badge bg-black rounded-pill">44</span>
              </li>{" "}
              <li class="list-group-item d-flex justify-content-between align-items-start bg-transparent text-white">
                <div class="ms-2 me-auto bg-transparent text-white">
                  <div class="fw-bold bg-transparent text-white m-2">
                    Tájékozódj a szolgáltatásokról!
                  </div>
                  <a
                    href="/Services"
                    className="text-decoration-none text-white bg-black rounded-3 p-1 m-2"
                  >
                    {" "}
                    <strong>Szolgáltatásaink bemutatása!</strong>
                  </a>
                </div>
                <span class="badge bg-black rounded-pill ">14</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start bg-transparent text-white">
                <div class="ms-2 me-auto bg-transparent text-white ">
                  <div class="fw-bold bg-transparent text-white m-2">
                    Foglalj időpontot!
                  </div>
                  <a
                    href="/Price"
                    className="text-decoration-none text-white bg-black rounded-3 p-1 m-2"
                  >
                    {" "}
                    <strong>Fodrászaink elérhetőségei.</strong>
                  </a>
                </div>
                <span class="badge bg-black rounded-pill">72</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start bg-transparent text-white">
                <div class="ms-2 me-auto bg-transparent text-white ">
                  <div class="fw-bold bg-transparent text-white m-2">
                    Nézz Be hozzánk!
                  </div>
                  <a
                    href="https://goo.gl/maps/mfZx9f9hmC64EPxe9"
                    className="text-decoration-none text-white bg-black rounded-3 p-1 m-2"
                  >
                    {" "}
                    <strong>Bronze Art Studio location!</strong>
                  </a>
                </div>
                <span class="badge bg-black rounded-pill">56</span>
              </li>
              <li class="list-group-item d-flex justify-content-between align-items-start bg-transparent text-white">
                <div class="ms-2 me-auto bg-transparent text-white ">
                  <div class="fw-bold bg-transparent text-white m-2 ">
                    Kövess minket! Légy mindig tájékozott!
                  </div>
                  <a
                    href="https://www.facebook.com/bronzeartstudio"
                    className="text-decoration-none text-white bg-black rounded-3 p-1 m-2"
                  >
                    {" "}
                    <strong>Facebook!</strong>
                  </a>
                </div>
                <span class="badge bg-black rounded-pill">99</span>
              </li>
            </ol>
          </div>
          <div className="text-center justify-content-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2696.3587976212148!2d19.069401915626237!3d47.48292327917662!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dcf8619612d9%3A0x4789342d55384b23!2zQnJvbnplIEFydCBTdMO6ZGnDsw!5e0!3m2!1shu!2shu!4v1672908730109!5m2!1shu!2shu"
              width="80%"
              height="auto"
              style={{ border: "0", textAlign: "center" }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="shadow-sm shadow-white   m-3 justify-content-center text-center"
              title="Budapest IX.ker Liliom utca 48 . Bronze Art Studio Fodrászat."
              alt="Budapest IX.ker Liliom utca 48 . Bronze Art Studio Fodrászat."
            >
              Budapest IX.ker Liliom utca 48 . Bronze Art Studio Fodrászat
            </iframe>
          </div>
        </div>

        <div className="flex-column justify-content-center      bg-transparent m-2">
          <strong>
            <h4 className="h1 text-center  text-white bg-black bg-opacity-50  ">
              Kövess minket Instagramon vagy Facebookon ,ahová folyamatosan
              feltöltjük a legújjabb munkáinkat. Így nem maradsz le semmiről.
            </h4>
          </strong>
        </div>
      </section>
    </>
  );
};
export default Home;
