import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <section className="error-page section">
      <div className="error-container text-center">
        <h1 className="text-light"> Ez az oldal sajnos nem létezik.</h1>
        <Link to="/home" className="btn btn-primary">
          Vissza a Bronze Art Studio Főoldalára.
        </Link>
      </div>
    </section>
  );
};
export default Error;
