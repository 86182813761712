import { FaAddressBook, FaMapMarkerAlt } from "react-icons/fa";
import React from "react";

export const contactinfo = [
  {
    id: 1,
    phone: "(30) 781 2503",
    phoneicon: <FaAddressBook />,
    address: "Liliom utca 48. , Budapest, Hungary",
    addressicon: <FaMapMarkerAlt />,
    openingHours: [
      { day: "Hétfő: 11:00-19:00 / Kedd: 09:30-19:00" },

      { day: "Szerda: 10:00-19:00 /Csütörtök: 11:00-19:00" },

      { day: "Péntek: 09:00-19:00" },
      { day: "Szombat/Vasárnap: Zárva" },
    ],
  },
];

export default contactinfo;
