import React from "react";
import { Link } from "react-router-dom";
import { BsSortAlphaDown, BsPen, BsChatLeftDots } from "react-icons/bs";
import "animate.css";
const Services = () => {
  return (
    <>
      <div className="row  justify-content-center m-5 animate__animated animate__bounceInLeft ">
        <h4 className=" h1 text-center mb-3 mt-1 text-white  ">
          Szolgáltatásaink
          <BsSortAlphaDown />
        </h4>
      </div>
      <section className="container-sm">
        <a className="text-decoration-none link-white " href="/olaplex">
          {" "}
          <div className="flex-row bg-dark bg-opacity-50 hover p-5 m-3">
            <h2 className=" text-center m-1 ">Olaplex!</h2>
          </div>
        </a>
        <a className="text-decoration-none link-white " href="/ombre">
          <div className="flex-row bg-dark bg-opacity-50 p-5 m-3">
            <h2 className=" text-center m-1">Ombre!</h2>
          </div>
        </a>{" "}
        <a className="text-decoration-none link-white " href="/balayage">
          {" "}
          <div className="flex-row bg-dark bg-opacity-50 p-5 m-3">
            <h2 className=" text-center m-1 ">Balayage!</h2>
          </div>
        </a>
        <a className="text-decoration-none link-white " href="/babylights">
          <div className="flex-row bg-dark bg-opacity-50 p-5 m-3">
            <h2 className=" text-center m-1 "> Babylights!</h2>
          </div>
        </a>
        <a className="text-decoration-none link-white " href="/joicok">
          <div className="flex-row bg-dark bg-opacity-50 p-5 m-3 link-white">
            <h2 className=" text-center m-1 ">Joico K-pak!</h2>
          </div>
        </a>
        <a className="text-decoration-none link-white " href="/ferfigyerek">
          <div className="flex-row bg-dark bg-opacity-50 p-5 m-3">
            <h2 className=" text-center m-1 ">Férfi és Gyermek hajvágás!</h2>
          </div>
        </a>
        <a className="text-decoration-none link-white " href="alkalmi">
          <div className="flex-row bg-dark bg-opacity-50 p-5 m-3">
            <h2 className=" text-center m-1 ">Alkalmi frizurák!</h2>
          </div>
        </a>
      </section>
    </>
  );
};
export default Services;
