import képek1 from "../imageall/babylightsimg/baby01.webp";
import képek2 from "../imageall/babylightsimg/baby02.webp";
import képek3 from "../imageall/babylightsimg/baby03.webp";
import képek4 from "../imageall/babylightsimg/baby04.webp";
export const images = [
  {
    id: 1,
    title: "Bemutató",
    text: ` A Babylights a finom árnyalatok szerelmeseinek javallott!
A szőkített tincsek szinte szálanként kerülnek kiválogatásra, festésre!  Az eredmény szemnek gyönyörködtető látvány! Képes visszaadni a hajadnak a gyermekkori természetességét úgy, hogy ezzel dúsabb, ragyogóbb frizura hatását kelt! Nem csak szőke hajszín esetében mutatós,bármely természetes hajszín választás során megállja a helyét!A Babaylights népszerű és kedvelt technika napjainkban!

`,
    image: képek1,
  },
  {
    id: 2,
    title: "Megjelenése",
    text: `  A fodrász szakemberek álma mindig egy olyan hatás elérése volt, amelyet akkor tapasztalhattunk utoljára, mikor gyermekként egy-egy napot játékkal töltöttünk a nyári napsütésben.  A módszer folyamatosan fejlődött az évek során, míg végül Babylights néven került be a köztudatba.`,
    image: képek2,
  },
  {
    id: 3,
    title: "Cél",
    text: ` Gyengéd, visszafogott, kontraszt nélküli megjelenés. Az arc körül összefogott haj állapotában is világos hatást kelt.
`,
    image: képek3,
  },
  {
    id: 4,
    title: "Ápolása",
    text: `Kötéserősítő, regeneráló , színmegőrző samponok, balzsamok és hajpakolások otthoni használata elengedhetetlen! 
    Fodrászodtól kérd ki szeméyre szabott tanácsát, véleményét hajadat illetően! A Babylights festés aprólékossága miatt, gyakoribb fodrászlátogatást igényel!`,
    image: képek4,
  },
];
export default images;
