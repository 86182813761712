import képek1 from "../imageall/alkalmiimg/alkalmi02.webp";
export const images = [
  {
    id: 1,
    title: "Bemutató",
    text: ` Fogadás? Estély? Esküvő? Vedd fel velünk a kapcsolatot, mond el az elképzeléseidet, álmodjuk meg közösen a frizurádat a nagy napra. Régóta mondjuk, hogy alkalmi frizuráknak csak a képzelet szabhat határt. Szakembereink leveszik a terhet a válladról, a kívánt frizura körüli ötletezés kapcsán, hogy te az előtted álló eseményre koncentrálhass.
`,
    image: képek1,
  },
];

export default images;
