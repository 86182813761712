import React from "react";
import {
  BsFillDoorClosedFill,
  BsFillCameraFill,
  BsCheckSquareFill,
} from "react-icons/bs";

import { FaInstagram, FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";

import { MdRuleFolder } from "react-icons/md";
import { ImPriceTag } from "react-icons/im";

export const links = [
  { id: 1, url: "/home", icon: <BsFillDoorClosedFill />, text: "Főoldal" },

  {
    id: 2,
    url: "/services",
    icon: <BsCheckSquareFill />,
    text: "Szolgáltatások",
  },
  {
    id: 3,
    url: "/price",
    icon: <ImPriceTag />,
    text: "Árak",
  },
  { id: 4, url: "/pictures", icon: <BsFillCameraFill />, text: "Képek" },
  {
    id: 5,
    url: "/rules",
    icon: <MdRuleFolder />,
    text: "Szabályzat",
  },
];
export const social = [
  {
    id: 1,
    url: "https://www.facebook.com/bronzeartstudio",
    text: "Facebook",
    icon: <FaFacebook />,
  },
  {
    id: 2,
    url:
      "https://www.instagram.com/explore/locations/1256583641060616/Bronze%20Art%20St%C3%BAdi%C3%B3/",
    text: "Instagram",
    icon: <FaInstagram />,
  },
];

export default links;

/* képek: BsFillCameraFill;
munkák: BsFillCheckSquareFill;
főoldal: BsFillDoorClosedFill;
szolgáltatás: BsBagFill;
ár: ImPriceTag;
szabály: MdRuleFolder;
 */
