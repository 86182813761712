import képek1 from "../imageall/joicokimg/joicok01.webp";

export const images = [
  {
    id: 1,
    title: "Bemutató",
    text: ` A haj roncsolásához sok minden hozzájárulhat. Mint például a hő, a vegyszerek vagy a környezeti elemek. A Joico kezelés a dehidratált hajat feltölti, illetve pótolj a szükséges aminosavakat.  
A Joico terápiának köszönhetően a durva, száraz, megviselt hajból ismét pillekönnyű, lebegő, egészséges hajkoronát varázsolhatunk. Ajánlott azoknak, akik vágynak egy egészséges, természetes gyönyörű hajra, amely a kezelés hatására fogékonyabb lesz a formázásra, továbbá rugalmasabbá, erőtől duzzadóvá és fényesebbé válik. 

`,
    image: képek1,
  },
];
export default images;
