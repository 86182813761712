import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { images } from "../data/picturedata";
import { BsCaretRightSquare } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Pictures = () => {
  const [loading, setLodaing] = useState(true);
  const [data, setData] = useState({});

  const getAllImage = () => {
    setData(images);
  };
  useEffect(() => {
    getAllImage();
    setInterval(() => {
      setLodaing(false);
    }, 3000);
  }, []);
  if (loading) {
    return (
      <>
        <div className="row  justify-content-center m-5 animate__animated animate__bounceInLeft ">
          <h4 className="h1 text-center mb-3 mt-1 text-white  ">Munkáink</h4>
          <q className="text-center text-white">
            <i>Az élet sokkal szebb ha megtalálod a fodrászodat!</i>
          </q>
        </div>

        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 justify-content-center">
              <div class="text-center">
                <span class="text-center text-white ">
                  Képek betöltése folyamatban...
                </span>{" "}
                <div
                  class="spinner-border display-1 text-white"
                  role="status"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="row  justify-content-center m-5 animate__animated animate__bounceInLeft ">
        <h4 className="h1 text-center mb-3 mt-1 text-white  ">Munkáink</h4>
        <q className="text-center text-white">
          <i>Az élet sokkal szebb ha megtalálod a fodrászodat!</i>
        </q>
      </div>
      <section className="container-sm">
        <div className="row m-2 justify-content-center bg-dark bg-opacity-50 ">
          <i className="position-relative top0 bottom-0 display-6 text-light">
            <BsCaretRightSquare />
          </i>

          {data.map((kép, index) => {
            const { id, img } = kép;

            return (
              <div key={index} className="col-sm-4 mb-2 m-2">
                <LazyLoadImage
                  className="  rounded-5  shadow-sm shadow-white"
                  src={img}
                  alt="Bronze Art Studóban készült fénykép, tökéletes hajformák, kiváló munka , gyönyörű színek."
                  title="Egy gyönyörű hajról készült fénykép ,hajkezelés után!"
                  loading="lazy"
                  effect="blur"
                />
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default Pictures;
