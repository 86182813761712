import képek1 from "../imageall/ombreimg/ombrehair1.webp";
import képek2 from "../imageall/ombreimg/ombrepélda.webp";
import képek3 from "../imageall/ombreimg/ombre02.webp";
export const images = [
  {
    id: 1,
    title: "Bemutató",
    text: ` Az Ombre jelentése nem más, mint "árnyjáték". Egy fodrász számára ez a haj töve és a haj vége közötti eltérő színátmenetet, árnyalást  jelenti.  Azt a hatást kelti, mintha nap szívta volna ki a haj színét. A technika alapja, hogy a fejtetőtől a hajvégekig, a sötétebb tónusokból indulva világosabb színt alakítunk ki.
 Az így kialakított kontraszt lesz az ombre, amely félhosszú és hosszú hajban is csodásan mutat!
`,
    image: képek1,
  },
  {
    id: 2,
    title: "Megjelenése",
    text: ` Az Ombre több mint tíz éve került be a köztudatba, hatalmas sikert aratva, hiszen nők milliói találták meg azt a hajfestési eljárást, amelyben nem szükséges a szőke színvilág eléréséhez a hajtövet festeni!Az ombre festés lényege, hogy a hajtő marad az eredeti szín vagy ahhoz hasonló árnyalat és egy finom átmenettel a hajvégeket a lehető legvilágosabb színre világosítjuk fel!`,
    image: képek2,
  },
  {
    id: 3,
    title: "Cél",
    text: `A természetesség és a praktikusság volt a fő irányelv. Azoknak való, akik nem szeretnek gyakran fodrászhoz járni vagy egyszerűen nincs idejük! Itt nincs lenövés, ezáltal a festések közötti idő kitolható akár három-tizenkét hónap közé!
 Remekül mutat hullámos és egyenes hajban is!
`,
    image: képek3,
  },
  {
    id: 4,
    title: "Ápolása",
    text: `Minden festett haj ápolást igényel, így érdemes Fodrászunknál érdeklődni a megfelelő termékek kiválasztása érdekében!`,
    image: "",
  },
];
export default images;
