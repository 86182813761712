import React, { useState } from "react";
import { Link } from "react-router-dom";

import { jobs } from "../data/arakdata";
import { RiMessengerLine, RiInstagramLine } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { BsFillTagFill } from "react-icons/bs";
import "animate.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Price = () => {
  const [price, setPrice] = useState(jobs);
  const [value, setValue] = useState(2); //0,1 vagy 2 lehet. a három lány neve alapján.
  console.log(value); //átadja a valuenak az index értékét
  console.log(price[value]); // megis találja az új index alapján a következő objektumot.
  const {
    id,
    name,
    type,
    img,
    bemutatkozo,
    services,
    urlface,
    urlinsta,
    phone,
    eszkoz,
  } = price[value];
  console.log(id, name, bemutatkozo, services);
  //const { id, name, img, bemutatkozo, services } = price[value];

  return (
    <>
      <div className="row  justify-content-center m-5 animate__animated animate__bounceInLeft ">
        <h4 className="h1 text-center mb-3 mt-1 text-white  ">
          Szakembereink Árlistája
        </h4>
      </div>
      <section className="container-sm ">
        <div className="row justify-content-center bg-black bg-opacity-25">
          <h4 className="text-center text-white">
            Válassz Szakembereink közül!
          </h4>
          <div
            className="btn-group btn-group-sm  justify-content-center animate__animated animate__flash flex-wrap"
            role="group"
            aria-label="Basic example"
          >
            {price.map((ar, index) => {
              const { id, name, type, img, services } = ar;
              return (
                <div>
                  <button
                    key={ar.id}
                    type="button"
                    className="btn bg-black  text-white  m-1 p-1"
                    onClick={() => {
                      setValue(index);
                    }}
                  >
                    {name} <br />
                    {type}
                  </button>
                </div>
              );
            })}
          </div>
          <q className="text-white text-center">
            <i>Keep calm and call your hairdresser!</i>
          </q>
        </div>

        <div className="row justify-content-center  animate__animated animate__backInUp">
          <div className="col-sm-6">
            <div className="card bg-dark bg-opacity-50  border-0 mt-2 p-1">
              <LazyLoadImage
                src={img}
                className="card-img-top border border-1 border-dark  mt-1 bg-dark bg-opacity-50"
                alt="Tomasovszki Melitta fodrász, Valla-Mursa Andrea fodrász, Csonak Kati fodrász bemutatkozója és szolgáltatásai."
                title="A fodrászaink bemutatkozói , szolgáltatások árai. Bronze Art Studio!"
                loading="lazy"
                effect="blur"
              />
              <div className="card-title bg-dark bg-opacity-50 text-white justify-content-center ">
                <h4 className="card-text text-white  text-center ">{name}</h4>
              </div>
              <div className="card-body border-0 bg-dark bg-opacity-50">
                <blockquote className="blockquote card-text fw-lighter text-white justify-content-center text-center">
                  {bemutatkozo}
                </blockquote>
                <p className="card-text text-white justify-content-center text-center">
                  {eszkoz}
                </p>
              </div>
              <div className="card-footer text-center text-white bg-dark bg-opacity-50">
                <h6 className="text-center text-white">
                  <u>Elérhetőség</u>
                </h6>

                <a
                  href={` tel: ${phone}`}
                  className="text-white text-decoration-none"
                >
                  <FiPhoneCall />
                  {phone}
                </a>

                <p className="text-white text-center">Instagram-Messenger</p>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center mt-2 ">
          <h1 className="text-center text-white">
            Árak <span class="badge bg-black text-white border-2">New</span>
          </h1>
          <div className="position-relative text-center  ">
            {/* top-50 end-0 */}
            <i className=" position-fixed bottom-0 start-0  bg-white bg-opacity-75    text-center animate__animated animate__bounceInRight">
              <p className="text-primary   ">
                Szeretnék időpontot kérni tőled {name}!
              </p>
              <ul className="list-group list-group-horizontal bg-white bg-opacity-10 ">
                <li className="list-group-item bg-white bg-opacity-10 p-0 m-0">
                  <a href={urlface} className="text-decoration-none">
                    <p className="text-primary">
                      <RiMessengerLine className=" display-6 text-primary" />
                      Messenger
                    </p>
                  </a>
                </li>
                <li className="list-group-item bg-white bg-opacity-10 p-0 m-0">
                  <a href={urlinsta} className="text-decoration-none">
                    <p className="text-info">
                      <RiInstagramLine className="display-6 text-info" />
                      Instagram
                    </p>
                  </a>
                </li>
                <li className="list-group-item bg-white bg-opacity-10 p-0 m-0">
                  <a href={` tel: ${phone}`} className="text-decoration-none">
                    <p className="text-primary">
                      <FiPhoneCall className=" display-6 text-primary" />
                      {phone}
                    </p>
                  </a>
                </li>
              </ul>
            </i>
            {/*  bottom-50 end-0 */}

            {/*  <i className=" position-fixed bottom-0 end-0 bg-white   rounded-3 m-1 text-center animate__animated animate__bounceInRight">
            <p className="text-primary">{name}</p>
            <a href={` tel: ${phone}`} className="text-decoration-none">
              <p className="text-primary">
                <FiPhoneCall className="display-6 text-primary" />
                {phone}
              </p>
            </a>
          </i> */}
            <p className="text-center text-white bg-black bg-opacity-25">
              <strong>Figyelem</strong> Extra mennyiségű haj,valamint ombre
              festés esetén illetve teljes hajvég világosítás során felárat
              számolunk fel szolgáltatásaikra.
            </p>
          </div>
          {services.map((type, index) => {
            const { name, Rövid, Félhosszú, Hosszú, Extra } = type;
            return (
              <div
                key={index}
                className="col-sm-8 text-center  bg-dark bg-opacity-50 text-white rounded-3 text-wrap justify-content-center m-1 fw-bolder"
              >
                <h5>
                  {name} <BsFillTagFill />
                </h5>
                <table className="table table-striped  text-white justify-content-center ">
                  <tr className="align-middle">
                    <td>{Rövid} </td>
                  </tr>
                  <tr>
                    <td>{Félhosszú}</td>
                  </tr>
                  <tr>
                    <td>
                      {Hosszú}
                      <BsFillTagFill />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {Extra}
                      <BsFillTagFill />
                    </td>
                  </tr>
                </table>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

{
  /* <div className="row justify-content-center bg-dark">
        {katiarakdata.map((ar, index) => {
          const { id, img, services } = ar;
          return (
            

              <div className="row justify-content-center mt-5">
                <h1 className="text-center mb-5 text-light">Árak</h1>

                {services.map((type, index) => {
                  const { name, Rövid, Félhosszú, Hosszú, Extra } = type;
                  return (
                    <div
                      key={index}
                      className="col-sm-3  bg-dark text-white rounded-3 text-wrap justify-content-center"
                    >
                      <h5>{name}</h5>
                      <table className="table table-striped text-light justify-content-center">
                        <tr>
                          <td>{Rövid}</td>
                        </tr>
                        <tr>
                          <td>{Félhosszú}</td>
                        </tr>
                        <tr>
                          <td>{Hosszú}</td>
                        </tr>
                        <tr>
                          <td>{Extra}</td>
                        </tr>
                      </table>
                    </div>
                  );
                })}
                <div className="position-relative">
                  <p>Phone:06 70 8864665</p>
                  <i className=" position-fixed bottom-0 end-0 bg-white rounded-3">
                    <a href={katiUrlFace}>
                      <RiMessengerLine className="display-1 text-primary" />
                    </a>
                    <a href={katiUrlInsta} className="text-decoration-none">
                      <RiInstagramLine className="display-1 text-primary" />
                    </a>
                  </i>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}; */
}
export default Price;
{
  /* export default Price;
/*
1. data object: id,név,áraskép,messenger link. 
2. map ból bekérés.  return értéke egy button ami a nevekre mutat.
3. onclick eseményre a név-hez tartozó id=index adatokat betölti.
4. legyen az a set value értéke.
5. a megvalósítás */
}
