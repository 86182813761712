import csk from "../imageall/mainimages/csonkakati.webp";
import ma from "../imageall/mainimages/mursaandrea.webp";
import tm from "../imageall/mainimages/tomasovszkimelitta.webp";
import lk from "../imageall/mainimages/karmen.webp";
import ko from "../imageall/mainimages/orsimain.webp";
import { BsFillTagFill } from "react-icons/bs";
export const jobs = [
  {
    id: "1",
    img: csk,
    name: "Csonka Kati",
    type: "Fodrász",
    bemutatkozo: `
    Munkámban a legnagyobb öröm az, ha a vendégeim elégedetten mosolyognak vissza rám a tükörből. Mert sikerült megvalósítani azt, amit ők megálmodtak. A szakmámat az életem részének tekintem. Azon szerencsés emberek köze tartozom, akik nagyon szeretik és élvezik munkájuk minden egyes pillanatát. Szeretek ötleteket adni a megfeleő frizura kiválasztásához, de ugyanakkor szívesen veszem a vendégeim által kiválasztott frizuráról hozott képet, információt, amely nagyrészt segíti a dolgom. Első találkozás alkalmával, fontos a megfeleő konzultáció és a diagnózis, amire nagy figyelmet fordítok. Így kezdődhet a közös jövő!Követem a legújabb hajvágási technikákat, trendeket, különböző stílus irányzatokat. Fontosnak tartom, hogy a vendég személyiségéhez közel álljon az adott frizura. Fő szakterületem a Hajfestés, Színkorrekció, Hajhosszabbítás.A legfontosabbnak azt tartom, hogy a haj egészséges és ápolt legyen.Professzionális hajfestékkel dolgozom! Mint példáúl a Nevitlay color hajfesték, amely ammónia és PPD mentes, illetve nyugtató, tápláló hajfesték. Érzékeny fejbőrüek, illetve bőrbetegségtől, irritációtól szenvedő vendégek számára is kíméletes.Továbbá,szívesen ajánlom vendégeim számára, személyre szabott Joico K-Pak hajmegújító hajkezelést, amely a sérült és igénybe vett haj számára biztosít regenerálódást.A titkom egyszerű, kizárólag minőségi anyagokkal dolgozom, igényes, barátságos környezetben fogadom vendégeimet, munkámért garanciát vállalok.Szeretettel Várlak!
`,
    eszkoz: "Nevitaly, JOICO termékekkel dolgozom",
    urlface: "https://www.facebook.com/profile.php?id=100082831179700",
    urlinsta: "https://www.instagram.com/csonka_kati_hairdesing/",
    phone: "+36(70)8864665",

    services: [
      {
        name: "Mosás + Szárítás ",
        Rövid: "Rövid 5.550 Ft",
        Félhosszú: "Félhosszú 6.500 Ft",
        Hosszú: "Hosszú 7.500 Ft",
        Extra: "Extra 8.500 Ft",
      },
      {
        name: "Vágás,Szárítás",
        Rövid: "Rövid 8.000 Ft",
        Félhosszú: "Félhosszú 9.000 Ft",
        Hosszú: "Hosszú 10.500 Ft",
        Extra: "Extra 12.500 Ft",
      },
      {
        name: "Tőfestés,Szárítás",
        Rövid: "Rövid 12.000 Ft",
        Félhosszú: "Félhosszú 14.500 Ft",
        Hosszú: "Hosszú 15.500 Ft",
        Extra: "Extra 19.500 Ft",
      },
      {
        name: "Festés, Szárítás + vágás",
        Rövid: "Rövid 12.500 Ft+1.000",
        Félhosszú: "Félhosszú 15.500 Ft +1.000",
        Hosszú: "Hosszú 17.500 Ft +1.000",
        Extra: "Extra 23.500 Ft+2.000",
      },
      {
        name: "Ombre hajfestés",
        Rövid: "Rövid 12.500 Ft",
        Félhosszú: "Félhosszú 15.500 Ft",
        Hosszú: "Hosszú 16.500 Ft",
        Extra: "Extra 22.500 Ft",
      },
      {
        name: "Melír, vágás, szárítás",
        Rövid: "Rövid 14.500 Ft",
        Félhosszú: "Félhosszú 16.500 Ft",
        Hosszú: "Hosszú 18.500 Ft",
        Extra: "Extra 22.500 Ft",
      },
      {
        name: "Teljes hajszín világosítás + festés",
        Rövid: "Rövid 16.500 Ft",
        Félhosszú: "Félhosszú 20.500 Ft",
        Hosszú: "Hosszú 22.500 Ft",
        Extra: "Extra 25.000 Ft-tól",
      },
      {
        name: "Balayage festés & vágás",
        Rövid: "Rövid 18.000 Ft",
        Félhosszú: "Félhosszú 25.000 Ft",
        Hosszú: "Hosszú 28.500 Ft",
        Extra: "Extra 35.000 Ft",
      },
      {
        name: "Balayage + tőfestés ",
        Rövid: "Rövid 19.500 Ft",
        Félhosszú: "Félhosszú 26.500 Ft",
        Hosszú: "Hosszú 29.000 Ft",
        Extra: "Extra 37.500 Ft",
      },
      {
        name: "Dauer",
        Rövid: "Rövid 6.500 Ft",
        Félhosszú: "Félhosszú 8.500 Ft",
        Hosszú: "Hosszú 9.000 Ft",
        Extra: "Extra 15.500 Ft",
      },
      {
        name: "Joico K-Pak megújító kezelés",
        Rövid: "Normál haj:7.500 Ft",
        Extra: "Extrahosszú haj:13.500 Ft",
      },
      {
        name: "Joico K-Pak megújító kezelés + vágás",
        Rövid: "Normál haj:15.500 Ft",
        Extra: "Extrahosszú haj:19.500 Ft",
      },
      {
        name: "Férfi hajvágás",
        Rövid: "5.000 Ft",
      },
      {
        name: "Gyermek hajvágás",
        Rövid: "3.000 Ft",
      },
      {
        name: "Hajhosszabítás",
        Rövid:
          "Az ár 100g európai prémium minőségű 100% emberi,egy fejről levágott szálirányos, tömör végű, puha, fényes copfokat,illetve az illesztés technikáját és a munkafolyamat (pl: felrakás, fazonra igazítás) díját tartalmazza. ",
      },
      {
        name: "Hajhosszabítás /színezés ára",
        Rövid:
          "Igény szerint a haj festése +10.000 Ft/100g, szőke haj igény esetén +15.000 Ft felár.",
      },
      {
        name: "Natúr barna festetlen hajak",
        Rövid: "40 cm: 85.000 Ft",
        Félhosszú: "45 cm: 90.000 Ft",
        Hosszú: "50 cm: 95.000 Ft",
        Extra: "55 cm: 100.000 Ft",
      },
      {
        name: "Natúr barna festetlen hajak",
        Rövid: "60 cm: 105.000 Ft",
        Félhosszú: "65 cm: 110.000 Ft",
        Hosszú: "70 cm: 120.000 Ft",
        Extra: "75 cm: 130.000 Ft / 80 cm: 140.000 Ft",
      },

      {
        name: " Hajhosszabítás Technikák",
        Rövid: "Keratinos hőillesztés",
        Félhosszú: "Szilikonos Mikro gyűrű",
        Hosszú: "Szilikonos Nano gyűrű",
      },
      {
        name: "Munkadíjak",
        Rövid: "Leszedés:150-/tincs",
        Félhosszú: "Újrarakás:450-/tincs",
        Hosszú: "Tincsezés:150-/tincs",
        Extra: "Hozott haj felrakása:450-/tincs",
      },
    ],
  },
  {
    id: "2",
    img: ma,
    name: "Valla-Mursa Andrea",
    type: "Fodrász",
    bemutatkozo: `Azt szeretném elérni, hogy mikor visszatérsz a szalonunkba, azt mond ..." oda vagyok a hajamért!" A nevem Valla-Mursa Andrea. Fő szakterületem a hajfestés, lágy melírok, színátmenetek összehangolása,elrontott festékek helyrehozása ,balayage és legújabb trendek ötvözése. Tizenéves korom óta vagyok szőke, volt már hajam leégetve, elrontva, csúnyán levágva. Pontosan tudom saját, átélt élményeimen keresztül, hogy mekkora traumát tud okozni egy ilyen eset. Ezért úgy kezelem a vendégeim haját, mintha a sajátom lenne. Természetesen követem a hajvágás trendeket is, ezt is személyre, stílusra igazítva. A legfontosabbnak azt tartom, hogy a haj egészséges legyen és ápolt, hiszen egy nő legfőbb éke a hajkoronája. Mesés színeket tudok keverni,dolgozom ammónia mentes anyagokkal,kedvezve ezzel a kismamáknak illetve a tudatosan ezt igénylő vendégeimnek. A legnagyobb márkák véleménye az ,hogy a haj kozmetikai állapota a legfőbb a kívánt szín elérése mellett, ezzel tudok azonosúlni munkám során. Nem vagyok sietős, kapkodós. A jó munkához idő kell. A személyre szabott munkák esetében ezt másképp nem is tudnám elképzelni.`,
    eszkoz: "Nevitaly,L'Oréal termékekkel dolgozom",
    phone: "+36(30)5086581",
    urlface: "https://www.facebook.com/mursahair",
    urlinsta: "https://www.instagram.com/mursa_hair/",

    services: [
      {
        name: "Vágás,Szárítás",
        Rövid: "Normál haj 8.000 Ft",
        Félhosszú: "Dús/hosszú haj 10.500 Ft",
      },
      {
        name: "Mosás+Szárítás",
        Rövid: "Normál haj 6.500 Ft",
        Félhosszú: "Dús/hosszú haj 8.000 Ft",
      },
      {
        name: "Tőfestés",
        Rövid: "Normál haj 17.000 Ft",
        Félhosszú: "Dús/hosszú haj 20.000 Ft",
      },
      {
        name: "Teljes Festés",
        Rövid: "Normál haj 25.000 Ft",
        Félhosszú: "Dús/hosszú haj 29.000 Ft",
      },
      {
        name: "Balayage",
        Rövid: "Normál haj 33.000 Ft",
        Félhosszú: "Dús/hosszú haj 38.000 Ft",
      },
      {
        name: "Babylights",
        Rövid: "Normál haj 34.000 Ft",
        Félhosszú: "Dús/hosszú haj 38.000 Ft",
      },
      {
        name: "Balayage árnyalás",
        Rövid: "Normál haj 19.000 Ft",
        Félhosszú: "Dús/hosszú haj 22.000 Ft",
      },
      {
        name: "Férfi hajvágás",
        Rövid: "3.500 Ft",
      },
      {
        name: "Festésekhez eü. vágás",
        Rövid: "2.500 Ft",
      },
      {
        name: "Fazon vágás festésekhez",
        Rövid: "4.000 Ft",
      },
      {
        name: "Hajkezelés festésekhez",
        Rövid: "3.000 Ft",
      },
      {
        name: "Metal detox",
        Rövid: "3.000 Ft",
      },
      {
        name: "Hajkezelés szárítással",
        Rövid: "10.500 Ft",
      },
    ],
  },

  {
    id: "3",
    img: tm,
    name: "Tomasovszki Melitta",
    type: "Fodrász",
    bemutatkozo: `
    Engedjétek meg, hogy bemutatkozzam,Tomasovszki Melitta vagyok,fodrász és hair stylist. 2020 óta vagyok a Bronze Art Csapat tagja. Képességeim és tudásom csiszolása ,fejlesztése céljából folyamatosan képzem magam,hogy nektek mindig a legjobbat tudjam nyujtani,hiszen megbíztok bennem. Szívesen meghallgatom elképzeléseiteket ,ötleteiteket az áhított frizurával kapcsolatban. A megvalósításban számíthattok rám.
    `,
    eszkoz: "Nevitaly,Schwarzkopf ,Olaplex termékekkel dolgozom",
    urlface: "https://www.facebook.com/melitta.tomasovszky",
    urlinsta: "https://www.instagram.com/tomasovszkimelitta_hairstylist/",
    phone: "+36(30)7812503",

    services: [
      {
        name: "Szárítás ",
        Rövid: "Rövid 3.550 Ft",
        Félhosszú: "Félhosszú 4.500 Ft",
        Hosszú: "Hosszú 5.500 Ft",
        Extra: "Extra 6.500 Ft",
      },
      {
        name: "Vágás,Szárítás",
        Rövid: "Rövid 7.500 Ft",
        Félhosszú: "Félhosszú 8.500 Ft",
        Hosszú: "Hosszú 9.500 Ft",
        Extra: "Extra 11.000 Ft",
      },
      {
        name: "Festés,Szárítás",
        Rövid: "Rövid 14.000 Ft",
        Félhosszú: "Félhosszú 15.500 Ft",
        Hosszú: "Hosszú 17.500 Ft",
        Extra: "Extra 19.000 Ft",
      },
      {
        name: "Festés, Szárítás +vágás",
        Rövid: "Rövid 14.500 Ft",
        Félhosszú: "Félhosszú 16.000 Ft",
        Hosszú: "Hosszú 18.000 Ft",
        Extra: "Extra 20.000 Ft",
      },
      {
        name: "Tőfestés, szárítás",
        Rövid: "Rövid 13.500 Ft",
        Félhosszú: "Félhosszú 14.500 Ft",
        Hosszú: "Hosszú 15.500 Ft",
        Extra: "Extra 16.000 Ft",
      },
      {
        name: "Tőfestés,vágás, szárítás",
        Rövid: "Rövid 14.500 Ft",
        Félhosszú: "Félhosszú 15.500 Ft",
        Hosszú: "Hosszú 16.500 Ft",
        Extra: "Extra 17.000 Ft",
      },
      {
        name: "Melír,festés,vágás,szárítás",
        Rövid: "Rövid 16.500 Ft",
        Félhosszú: "Félhosszú 19.000 Ft",
        Hosszú: "Hosszú 21.000 Ft",
        Extra: "Extra 22.500 Ft-tól",
      },
      {
        name: "Teljes hajszín világosítás,festés",
        Rövid: "Rövid 21.500 Ft",
        Félhosszú: "Félhosszú 24.500 Ft",
        Hosszú: "Hosszú 28.000 Ft",
        Extra: "Extra 30.000 Ft-tól",
      },
      {
        name: "Balayage festés & vágás ",
        Rövid: "Rövid 18.000 Ft",
        Félhosszú: "Félhosszú 22.500 Ft",
        Hosszú: "Hosszú 24.500 Ft",
        Extra: "Extra 27.000 Ft",
      },
      {
        name: "Balayage + tőfestés & vágás ",
        Rövid: "Rövid 19.000 Ft",
        Félhosszú: "Félhosszú 23.500 Ft",
        Hosszú: "Hosszú 25.500 Ft",
        Extra: "Extra 28.000 Ft",
      },
      {
        name: "Olaplex szalon ápolás + vágás",
        Rövid: "13.000 Ft",
      },
      {
        name: "Olaplex ápolás festéskor",
        Rövid: "3.500 Ft",
      },
      {
        name: "Alkalmi frizurák kontyok",
        Rövid: "10.000 Ft-tól",
      },

      {
        name: "Férfi hajvágás",
        Rövid: "4.000 Ft",
      },
      {
        name: "Gyermek hajvágás",
        Rövid: "3.000 Ft",
      },
    ],
  },
  {
    id: "5",
    img: ko,
    name: "Kardos Orsolya",
    type: "Fodrász",
    bemutatkozo: `
    Kardos Orsolya vagyok! 2010 -ben végeztem el a szakmát ,amelybe azóta is töretlenül szerelmes vagyok! 
Rendszeresen járok oktatásokra, képzésekre, 
Igyekszem naprakész lenni a legújabb trendeket illetően! Minőségi ,professzionális anyagokkal dolgozom , mint a Mátrix vagy az Idhair termékcsalád! Számomra fontos , hogy a Vendégek elégedetten távozzanak a székemből, így minden nap arra törekszem, hogy a maximumot nyújtsam munkám során! Sok szeretettel várok minden szépülni Vágyó Hölgyet ! `,
    eszkoz: "",
    urlface: "https://www.facebook.com/profile.php?id=100057522554908",
    urlinsta: "#",
    phone: "+36(30)3071723",
    services: [
      {
        name: "Az árlista feltöltés alatt áll.",
        Rövid: "",
      },
    ],
  },
  {
    id: "4",
    img: lk,
    name: "Lovicsek Kármen",
    type: "Műköröm",
    bemutatkozo: `
    Sziasztok Lovicsek Kármen vagyok és 2020-ban még csak elvégeztem az iskolát viszont 2021 óta aktívan dolgozom benne és azóta járok továbbképzésekre illetve mindig követem az aktuális trendeket.
A precizitásra törekvés és a szorgalmam a fő erényeim a szakmában. 
Igyekszem,hogy mindig minőségi munkát adjak ki a kezeim közül, és a Vendégeim is elégedetten távozzanak, bizalmukkal megtiszelve.
Munkám során kizárólag professzionális termékekkel és eszközökkel dolgozok!
    `,
    eszkoz: "",
    urlface: "https://www.facebook.com/karmenlovicsek",
    urlinsta: "https://www.instagram.com/karmenitanls/",
    phone: "+36(70)6209920",
    services: [
      {
        name: "Kizárólag Erősített / Oldható géllakkot készítek! ",
        Rövid: "Az általam készített körmökre 1 hét garanciát vállalok! ",
      },
      {
        name: "Oldható géllak /  Csere ",
        Rövid: "6500  / 6000 Ft ",
      },
      {
        name: "Erősített géllak /  Csere ",
        Rövid: "7500  / 7000 Ft",
      },
      {
        name: "Festett minta / ujj ",
        Rövid: "200 - 500 Ft ",
      },
      {
        name: "Francia egyéb kisebb minta / ujj ",
        Rövid: "100 Ft ",
      },
      {
        name: "Teli kő / 2 ujj ",
        Rövid: "500 Ft ",
      },
      {
        name: "Csillámpor 10 ujj esetén ",
        Rövid: "500 Ft ",
      },
      {
        name: "Az ár 2 uj egyszerűbb díszítését tartalmazza ",
        Rövid: "Például : csillámpor / nyomda / matrica ",
      },
      {
        name: "Végleges eltávolítás / Manikürrel ",
        Rövid: "3000 / 4000 Ft",
      },
      {
        name: "Láb géllak / Csere ",
        Rövid: "6000 Ft",
      },
      {
        name: "1 hét után Pótlás / Javítás ",
        Rövid: "500 Ft",
      },
    ],
  },
];

export default jobs;
